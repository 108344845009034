<template>
  <v-card>
    <v-card-title>{{ $t("users.edit.password.title") }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" v-if="!data || (data && !msg.show)">
        <v-row>
          <v-col cols="12" md="4" v-if="!data">
            <v-text-field
              v-model="currentPwd"
              :rules="rules.required"
              :label="$t('users.edit.password.currentPassword')"
              :type="plainPwd ? 'text' : 'password'"
              :tabIndex="10001"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :md="data ? 6 : 4">
            <v-text-field
              v-model="pwd"
              :rules="rules.required"
              :label="$t('users.edit.password.password')"
              :type="plainPwd ? 'text' : 'password'"
              :tabIndex="10002"
            ></v-text-field>
          </v-col>

          <v-col cols="12" :md="data ? 6 : 4">
            <v-text-field
              v-model="pwd2"
              :label="$t('users.edit.password.passwordValidation')"
              :rules="rules.pwdvalidate"
              :type="plainPwd ? 'text' : 'password'"
              :tabIndex="10003"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-switch v-model="plainPwd" :label="$t('users.edit.password.showPassordInClear')"></v-switch>
      </v-form>
      <v-alert v-if="msg.show" text :color="msg.color" icon="mdi-alert-circle" border="left">
        {{ msg.text }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="!data || (data && !msg.show)" color="primary" @click="changePassword()">{{ $t("users.edit.password.change.submit") }}</v-btn>
      <v-btn v-else color="primary" @click="$router.push('/')">{{$t("users.edit.password.change.backToLogin")}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { formatMixin, listMixin, formMixin } from "@/helpers/mixins";

import { mapGetters } from "vuex";

export default {
  mixins: [formatMixin, listMixin, formMixin],

  data() {
    return {
      plainPwd: false,
      currentPwd: "",
      pwd: "",
      pwd2: "",
      valid: false,
      msg: {
        show: false,
        color: "green",
        text: "Default text",
      },
    };
  },

  props: {
    user: {
      type: Object,
      default: () => {},
    },
    data: {
      type: String,
    },
  },

  computed: {
    ...mapGetters(["userId"]),
  },

  methods: {
    getData: function() {
      return this.user;
    },

    reset: function() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },

    changePassword: function() {
      if (this.$refs.form.validate()) {
        let url = `/api/app/changepwd`;

        axios
          .post(url, {
            currentPassword: this.currentPwd,
            newPassword: this.pwd,
            data: this.data,
          })
          .then(() => {
            this.reset();
            this.msg.text = this.$t("users.edit.password.change.successfull");
            this.msg.color = "green";
            this.msg.show = true;
          })
          .catch(() => {
            this.msg.text = this.$t("users.edit.password.change.error");
            this.msg.color = "red";
            this.msg.show = true;
          });
      }
    },
  },
};
</script>
